import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import CalendarTodayIcon from "@mui/icons-material/CalendarMonth";
import { IconButton, Stack } from "@mui/material";
import { SHIFT_PRIORITY_COLOR } from "@src/appV2/lib/Theme/constants";
import { useGetWorkerRankings } from "@src/appV2/Rankings/api/useGetWorkerRankings";
import pluralize from "pluralize";

const MAX_PAGE_SIZE = 200;

export interface ATeamCopyProps {
  workerType: string;
  workplaceId: string;
}

export function ATeamCopy(props: ATeamCopyProps) {
  const { workerType, workplaceId } = props;
  const { data: workerRankings } = useGetWorkerRankings(
    {
      fields: {
        ranking: ["workerType", "workplace.id", "aTeamRosterSize"],
      },
      filter: {
        isOnATeam: true,
      },
      page: {
        size: MAX_PAGE_SIZE,
      },
    },
    // Since this call is made on every shift render, we want to cache the results for 5 minutes
    {
      staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
      cacheTime: 5 * 60 * 1000, // 5 minutes in milliseconds
    }
  );

  const {
    attributes: { aTeamRosterSize },
  } = workerRankings?.data?.find(
    (ranking) =>
      ranking.attributes.workerType === workerType &&
      ranking.relationships?.workplace?.data?.id === workplaceId
  ) ?? { attributes: {} };

  function getATeamCopyText() {
    if (!isDefined(aTeamRosterSize) || aTeamRosterSize === 0) {
      return null;
    }
    if (aTeamRosterSize === 1) {
      return "This workplace selected just you to have Priority Access to this shift. Book now before it ends!";
    }
    return `This workplace selected just you and ${aTeamRosterSize - 1} other ${pluralize(
      "professional",
      aTeamRosterSize - 1
    )} to have Priority Access to this shift. Book now before it ends!`;
  }

  const aTeamCopyText = getATeamCopyText();

  return (
    <Stack justifyContent="center" marginY={1} gap={1}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <IconButton sx={{ color: SHIFT_PRIORITY_COLOR, padding: 0 }} size="small">
          <CalendarTodayIcon />
        </IconButton>
        <Text
          variant="h4"
          align="center"
          sx={{
            color: SHIFT_PRIORITY_COLOR,
            fontWeight: "600",
            marginTop: "4px",
            // FIXME: fontSize is not applied due to global.scss override for ion-item.
            // remove !important when porting to V2
            fontSize: "16px !important",
          }}
        >
          Priority Plus Access
        </Text>
      </Stack>
      {aTeamCopyText && (
        <Text
          variant="body2"
          align="center"
          sx={{
            color: SHIFT_PRIORITY_COLOR,
            fontWeight: "600",
            fontSize: "12px",
          }}
        >
          {aTeamCopyText}
        </Text>
      )}
    </Stack>
  );
}
